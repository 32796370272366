/* :root{
  --themeclr:#f6df2d
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
:root{
  /* --themeclr: #F6DF2D; */
  /* --themeclr:#BD9C19; */
  --themeclr:#14c8b9;
  --themebtnclr:#20a599;

  --textclr:#DEC508;
  /* --themebtnclr:#4F862D; */

  --genhover:#57fff1;
  --whiteclr: #fff;
}


body{
  background-color: #000 !important;
  /* background-image: url(./Assets/images/bg.png); */
  color:#fff;
  font-family: medium;
  /* font-family: 'Poppins', sans-serif !important;
  font-size: 15px !important; */
}
@font-face {
  font-family: themefont;
  src: url(./Assets/Font/Gilroy-Black.ttf);
}
@font-face {
  font-family: thin;
  src: url(./Assets/Font/Gilroy-Thin.ttf);
}
@font-face {
  font-family: bold;
  src: url(./Assets/Font/Gilroy-Bold.ttf);
}
@font-face {
  font-family: extrabold;
  src: url(./Assets/Font/Gilroy-ExtraBold.ttf);
}
@font-face {
  font-family: medium;
  src: url(./Assets/Font/Gilroy-Medium.ttf);
}
@font-face {
  font-family: normal;
  src: url(./Assets/Font/Gilroy-Regular.ttf);
}
@font-face {
  font-family: light;
  src: url(./Assets/Font/Gilroy-Light.ttf);
}
table{
  color: #fff !important;
}
.list-group{
  background-color: #000;
}
.list-group a,.list-group-item{
  color: #fff !important;
  background-color: #000 !important;
}
.btn-link:focus{
  box-shadow: unset !important;
}
.list-group-item.active{
  border-color: transparent !important;
}
.list-group-item-action{
  /* margin-left: 8px; */
}
.list-group-item-action:hover, .list-group-item-action:focus,.list-group-item-action.active{
  /* background-color: var(--themeclr) !important; */
    background: url("./Assets/images/underline.png") no-repeat;
    /* background-size: 150px 4px; */
    background-size: 100%;
    background-position-y: bottom;
}
.list-group-item-action:hover , .list-group-item-action:focus,.list-group-item-action.active {
  color: var(--genhover) !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

header, main, footer {
  padding-left: 300px;
}

@media only screen and (max-width : 992px) {
  header, main, footer {
    padding-left: 0;
  }
}
.jc-between{
  justify-content: space-between !important;
}

/* NEW CSS */
.logo{
  height: 45px;
}
.border-right {
  border-right: 1px solid #505050 !important;
}
.asrt-table-foot {
  color: #fff;
}
.navbar.bg-primary
{
  background: #000 !important;
  border-bottom: 1px solid #fff;
}
#page-content-wrapper .text-primary {
  /* color: var(--themeclr) !important; */
  color: var(--whiteclr) !important;
}

.btn.btn-outline-primary,.btn-theme{
  /* background-image: linear-gradient(#7aff58, #a6f232, #e3fc0f) !important;
  color: black !important; */
    background: var(--themebtnclr) !important;
    border: 1px solid var(--themebtnclr) !important;
    /* font-family: 'extrabold'; */
    font-weight: 500;
    border-radius: 8px;
    position: relative;
    min-width: 120px;
    padding: 8px 20px;
    color: #fff;
}
.btn.btn-outline-primary:hover,.btn-theme:hover {
  background: var(--themeclr) !important;
  border: 1px solid var(--themeclr) !important;
  color: #fff;
}
.btn.btn-outline-primary:after,.btn-theme:after{
  /* content: ""; */
    width: 100%;
    height: 100%;
    border: 1px solid white;
    position: absolute;
    left: -5px;
    bottom: 4px;
    transition: 0.2s;
}
.loginsec{
  background-color: #000 !important;
  border: 1px solid #fff !important;
}
.userbtn {
  background: url("./Assets/images/livebutton.png") no-repeat;
  background-size: 100% 100%;
  background-position: center;
  color: #fff !important;
  min-width: 190px;
  min-height: 50px;
  font-size: 14px;
  font-weight: 500;
  padding: 0px 16px;
}
.userbtn:focus {
  box-shadow: unset !important;
}
.userbtn:hover{
  color: #57fff1 !important;
}
.loginsec label {
  color: #fff;
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link,.navbar-dark .navbar-brand{
  color:#fff !important;
}
.btn-link
{
  color: var(--whiteclr)   !important;
}
.input-group span {
  background: black !important;
  color: #fff;
  border-radius: 0;
}
.pagination li a {
  background: #000 !important;
}
th{
color: var(--themeclr) !important;
}


.card_dark_user.bg-secondary,.card.bg-primary{
   background-color: #0f0f0f !important;
   color: #fff !important;
   border: 1px solid var(--whiteclr) !important;
}
.loginlogo{
  width: 100%;
  max-width: 40%;
  margin: auto;
  margin-top: 15px;
}
.btn-primary
{
/* background-color: #a1cb00   !important; */
background: var(--themeclr) !important;
border-color: var(--themeclr)   !important;
/* color:#000 !important */
color:#fff !important
}
.btn-light{
  color: #fff !important;
  background-color: var(--themebtnclr) !important;
  border-color: var(--themebtnclr) !important;
  font-weight: 500 !important;
  transition: 0.5s;
}
.btn-light:hover{
  background: var(--themeclr) !important;
  border-color: var(--themeclr) !important;
  transition: 0.5s;
}
.btn-light:hover svg{
  filter: brightness(0.3);
  transition: 0.5s;
}
.responsive-table .col-md-12{
    /* width: 100%; */
    overflow-x: auto;
    /* max-width: 100%; */
}
.responsive-table {
  margin-top: 33px;
  overflow: hidden;
}
.hut-cont label {
  color: #fff;
  margin-top: 10px;
}

.input-group span {
  background: #2e2e2b !important;
  color: #fff;
  border-radius: 0 4px 4px 0;
  border-left: 0;
}


.ml-auto
{
  margin-left:auto !important;
}

.float-right {
  float: right !important;
}

@media only screen and (min-width:992px){
  .right_sec_align
  {
    display: flex;
    justify-content: flex-end;
  }

}
@media(max-width:991px){
  #sidebar-wrapper .list-group {
    width: 11rem !important;
}
}

input[type="file"]{
  padding: 3px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #2e2e2e;
}
.table_filter{
  margin-bottom: 10px;
}
.uploads_img {
  max-width: 130px !important;
  margin: auto;
}